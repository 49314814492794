import { parseISO, isValid, isFuture, differenceInYears } from 'date-fns';

export const validateFirstName = (firstName) => {
  firstName = firstName.trim();
  const regex = /^[a-zA-Z]{2,30}$/;
  return regex.test(firstName) ? null : "First name must be 2-30 characters long and contain only letters.";
};

export const validateLastName = (lastName) => {
  lastName = lastName.trim();
  const regex = /^[a-zA-Z]{2,30}$/;
  return regex.test(lastName) ? null : "Last name must be 2-30 characters long and contain only letters.";
};

export const validateEmail = (email) => {
  email = email.trim();
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email) ? null : "Invalid email address.";
};

export const validatePhone = (phone, isVerified=null) => {
  phone = phone.trim();
  const strippedPhone = phone.replace(/\D/g, '');

  if (strippedPhone.length === 10) {
    if(isVerified !== null) {
      if (isVerified) {
        return null;
      } else {
        return "Phone number must be verified.";
      }
    }
    return null;
  } else {
    return "Invalid phone number.";
  }
};

export const validatePassword = (password) => {
    // This regex checks for at least one letter, one number, and one special character
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?~`-])[A-Za-z\d!@#$%^&*()_+[\]{};':"\\|,.<>/?~`-]{6,}$/;

    if (!password) {
        return "Password is required.";
    } else if (!regex.test(password)) {
        return "Password must be at least 6 characters long, contain at least one letter, one number, and one special character.";
    }

    return null;
};

export const validateConfirmPassword = (password, confirmPassword) => {
  return password === confirmPassword ? null : "Passwords do not match.";
};

export const validateLicenseIssueDate = (licenseIssueDate) => {
  const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;

  if (!regex.test(licenseIssueDate)) {
    return "License issue date must be in the format YYYY-MM-DD.";
  }

  const parsedDate = parseISO(licenseIssueDate);

  if (!isValid(parsedDate)) {
    return "License issue date is invalid.";
  }

  if (isFuture(parsedDate)) {
    return "License issue date cannot be in the future.";
  }

  if (differenceInYears(new Date(), parsedDate) > 10) {
    return "License issue date is too old. Please enter a more recent date.";
  }

  return null;
};

export const validateLicenseExpiration = (licenseExpiration) => {
  const regex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;

  if (!regex.test(licenseExpiration)) {
    return "License expiration date must be in the format YYYY-MM-DD.";
  }

  const parsedDate = parseISO(licenseExpiration);

  if (!isValid(parsedDate)) {
    return "License expiration date is invalid.";
  }

  if (!isFuture(parsedDate)) {
    return "License expiration date must be in the future.";
  }

  if (differenceInYears(parsedDate, new Date()) > 10) {
    return "License expiration date is too far in the future.";
  }

  return null;
};

