'use client'

import { createContext, useContext, useState } from 'react'
import { Link } from "react-router-dom";

const TableContext = createContext({
  bleed: false,
  dense: false,
  grid: false,
  striped: false,
})

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function Table({ bleed = false, dense = false, grid = false, striped = false, className, children, ...props }) {
  return (
    <TableContext.Provider value={{ bleed, dense, grid, striped }}>
      <div className="flow-root">
        <div {...props} className={classNames(className, ' overflow-x-auto whitespace-nowrap  border rounded-xl')}>
          <div className={classNames('inline-block w-full m-auto align-middle', !bleed && 'sm:px-[--gutter]')}>
            <table className="min-w-full text-left text-sm">{children}</table>
          </div>
        </div>
      </div>
    </TableContext.Provider>
  )
}

export function TableHead({ className, ...props }) {
  return <thead {...props} className={classNames(className, 'text-zinc-500')} />
}

export function TableBody(props) {
  return <tbody {...props} />
}

const TableRowContext = createContext({
  href: undefined,
  target: undefined,
  title: undefined,
})

export function TableRow({ href, target, title, className, ...props }) {
  let { striped } = useContext(TableContext)

  return (
    <TableRowContext.Provider value={{ href, target, title }}>
      <tr
        {...props}
        className={classNames(
          className,
          href &&
          'has-[[data-row-link][data-focus]]:outline has-[[data-row-link][data-focus]]:outline-2 has-[[data-row-link][data-focus]]:-outline-offset-2 has-[[data-row-link][data-focus]]:outline-blue-500',
          striped && 'even:bg-zinc-950/[2.5%]',
          href && striped && 'hover:bg-zinc-950/5',
          href && !striped && 'hover:bg-zinc-950/[2.5%]'
        )}
      />
    </TableRowContext.Provider>
  )
}

export function TableHeader({ className, ...props }) {
  let { bleed, grid } = useContext(TableContext)

  return (
    <th
      {...props}
      className={classNames(
        className,
        'border-b border-b-zinc-950/10 px-6 py-2 font-medium',
        grid && 'border-l border-l-zinc-950/5 first:border-l-0',
        !bleed && 'sm:first:pl-1 sm:last:pr-1'
      )}
    />
  )
}

export function TableCell({ className, children, ...props }) {
  let { bleed, dense, grid, striped } = useContext(TableContext)
  let { href, target, title } = useContext(TableRowContext)
  let [cellRef, setCellRef] = useState(null)

  return (
    <td
      ref={href ? setCellRef : undefined}
      {...props}
      className={classNames(
        className,
        'relative px-6',
        !striped && 'border-b border-zinc-950/5',
        grid && 'border-l border-l-zinc-950/5 first:border-l-0',
        dense ? 'py-2.5' : 'py-4',
        !bleed && 'sm:first:pl-1 sm:last:pr-1'
      )}
    >
      {href && (
        <Link
          data-row-link
          to={href}
          aria-label={title}
          tabIndex={cellRef?.previousElementSibling === null ? 0 : -1}
          className="absolute inset-0 focus:outline-none"
        />
      )}
      {children}
    </td>
  )
}
