import Button from "../Button/Button"

export default function DashboardHeader({headerTitle, headerSubtitle, buttonComponent}) {

    function optionallyRenderSubtitle() {
        if (headerSubtitle) {
            return (
                <div className="mt-1 text-gray-600 text-sm">
                    {headerSubtitle === "loading" ? 
                        <span className="bg-gray-200 h-6 w-32 block animate-pulse rounded-lg"></span>
                        : headerSubtitle
                    } 
                </div>
            )
        }
    }

    function optionallyRenderButton() {
       return buttonComponent && buttonComponent()
    }

    return (
        <div className="flex flex-col space-y-3 lg:flex-row lg:items-center justify-between mb-6">
            <div className="max-w-lg pt-4">
                <h2 className="text-2xl font-bold text-gray-900">{headerTitle}</h2>
                {optionallyRenderSubtitle()}
            </div>
            {optionallyRenderButton()}
        </div>
    )
}