import React, { useState, useEffect } from 'react'
import axios from '../../utils/axios';
import AccountUsersTable from '../../components/Table/AccountUsersTable';
import { useAuth } from '../../context/AuthContext/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import DashboardHeader from '../../components/Dashboard/DashboardHeader';
import Button from '../../components/Button/Button';
import Modal from '../../components/Modal/Modal';
import FormElement from '../../components/Forms/Forms';
import { validateEmail, validateFirstName, validateLastName } from '../../utils/validation';


const AddUserModal = ({showAddUserModal, setShowAddUserModal, saveUser, user, handleUserChange, accountType, invalidFields, addUserError, roleOptions, addUserLoading}) => {

    return (
        <Modal show={showAddUserModal} allowCloseVeilClick={true} handleClose={() => setShowAddUserModal(false)} size="3xl">
            <div className="mb-6">
                <h3 className="text-2xl font-bold text-gray-900 text-left">Add User</h3>
            </div>
            <div>
                <FormElement
                    id="userRole"
                    name="userRole"
                    label="User Role:"
                    displayRow={true}
                    showLabel={true}
                    variant="select"
                    onChange={(e) => handleUserChange({target: {name: 'role', value: e.target.value}})}
                    value={user.role}
                    required={true}
                >
                    <option value="">Select Role</option>
                    {roleOptions.map((role) => (
                        <option key={role.key} value={role.key}>{role.value}</option>
                    ))}

                </FormElement>
                </div>
            <div className="grid grid-cols-1 gap-x-6 sm:grid-cols-6 w-full mt-2">
                {user.role === 'DRIVER' ?
                <div className="col-span-6 text-center">Please add drivers from the <Link to="/dashboard/fleet" className="text-blue-500 hover:text-blue-700 hover:underline">fleet page</Link></div> :
                <>
                <div className="col-span-2 text-left">
                    <FormElement
                        variant="input"
                        name="firstName"
                        label="First Name"
                        showLabel
                        value={user.firstName}
                        required={true}
                        onChange={(e) => handleUserChange({target: {name: 'firstName', value: e.target.value}})}
                        isInvalid={invalidFields.includes('firstName')}
                    />
                </div>
                <div className="col-span-2 text-left">
                    <FormElement
                        variant="input"
                        name="lastName"
                        label="Last Name"
                        showLabel
                        value={user.lastName}
                        required={true}
                        onChange={(e) => handleUserChange({target: {name: 'lastName', value: e.target.value}})}
                        isInvalid={invalidFields.includes('lastName')}
                    />
                </div>
                <div className="col-span-2 text-left">
                    <FormElement
                        variant="input"
                        name="email"
                        label="Email"
                        showLabel
                        value={user.email}
                        required={true}
                        onChange={(e) => handleUserChange({target: {name: 'email', value: e.target.value}})}
                        isInvalid={invalidFields.includes('email')}
                    />
                </div>
                </>
            }
            </div>
            {addUserError && <div className="text-red-500 my-4">{addUserError.map((error) => <div key={error}>{error}</div>)}</div>}
            <div className="flex justify-between items-center gap-4 mt-4">
                <Button variant="tertiary" className="flex-grow" onClick={() => setShowAddUserModal(false)}>Cancel</Button>
                <Button variant="primary" className="flex-grow" onClick={() => saveUser()} loading={addUserLoading}>Add User</Button>
            </div>
        </Modal>
    )
}

const RemoveUserModal = ({showRemoveUserModal, setShowRemoveUserModal, handleRemoveUser, removeUserLoading, removeUser}) => {
    return (
        <Modal show={showRemoveUserModal} allowCloseVeilClick={true} handleClose={() => setShowRemoveUserModal(false)} size="3xl">
            <div className="mb-6">
                <h3 className="text-2xl font-bold text-gray-900 text-left">Remove User</h3>
                <p className="text-gray-500 text-left">Are you sure you want to remove this user?</p>
                {removeUser && (
                    <>
                <p className="text-gray-500 text-left">Role: {removeUser.role}</p>
                <p className="text-gray-500 text-left">Name: {removeUser.name}</p>
                <p className="text-gray-500 text-left">Email: {removeUser.email}</p>
                </>
                )}
            </div>
            <div className="flex justify-between items-center gap-4 mt-4">
                <Button variant="tertiary" className="flex-grow" onClick={() => setShowRemoveUserModal(false)}>Cancel</Button>
                <Button variant="primary" className="flex-grow" onClick={() => handleRemoveUser()} loading={removeUserLoading}>Remove User</Button>
            </div>
        </Modal>
    )
}

const AccountUsersPage = () => {
    const [users, setUsers] = useState([]);
    const [accountType, setAccountType] = useState(null);
    const [user, setUser] = useState({firstName: '', lastName: '', email: '', role: '', accountType: ''});
    const [loading, setLoading] = useState(true);
    const [addUserLoading, setAddUserLoading] = useState(false);
    const [removeUserLoading, setRemoveUserLoading] = useState(false);
    const [error, setError] = useState(null);
    const [addUserError, setAddUserError] = useState(null);
    const [invalidFields, setInvalidFields] = useState([]);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
    const [removeUser, setRemoveUser] = useState(null);
    const [roleOptions, setRoleOptions] = useState([{ key: 'ADMIN', value: 'Admin' }, { key: 'MANAGER', value: 'Manager' }]);
    const { profile } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if(!profile?.roles?.primary === 'HOST_ADMIN' || !profile?.roles?.primary === 'CARRIER_ADMIN') {
            navigate('/dashboard');
        }
        if(users.length === 0) {
            getUsers();
        }
    }, []);

    const getUsers = async () => {
        try {
            const response = await axios.get('/dashboard/account/users');
            setUsers(response.data.users);
            setUser({ firstName: '', lastName: '', email: '', role: '', accountType: response.data.accountType});
        if(response.data.accountType === 'CARRIER' && !roleOptions.some(role => role.key === 'DRIVER')) {
                setRoleOptions([...roleOptions, {key: 'DRIVER', value: 'Driver'}]);
            }
            setLoading(false);
        } catch (error) {
            setError(error.response.data.message);
        }
    }

    const handleRemoveUser = async () => {
        setRemoveUserLoading(true);
        try {
            const response = await axios.delete(`/dashboard/account/users/${removeUser.userId}/${removeUser.role}`);
            if(response.status === 200){
                getUsers();
                setError(null);
            }
        } catch (error) {
            setError(error.response.data.message);
        } finally {
            setRemoveUserLoading(false);
            setShowRemoveUserModal(false);
        }
    }

    const handleUserChange = (e) => {
        if(addUserLoading && addUserError.length > 0){
            setAddUserLoading(false);
        }
        const { name, value } = e.target;
        setUser({...user, [name]: value});
    }

    const saveUser = async () => {
        setAddUserLoading(true);
        const errors = {};

            const firstNameError = validateFirstName(user.firstName);
            const lastNameError = validateLastName(user.lastName);
            const emailError = validateEmail(user.email);
            const roleError = !user.role && 'User role is required';

            if (firstNameError) errors.firstName = firstNameError;
            if (lastNameError) errors.lastName = lastNameError;
            if (emailError) errors.email = emailError;
            if (roleError) errors.role = roleError;

            if (Object.keys(errors).length > 0) {
                setInvalidFields(Object.keys(errors));

                const formattedErrors = [...new Set(Object.values(errors))];
                setAddUserError(formattedErrors);
                return;
            }


            if ((errors.length === 0 || !errors.length)) {
                setInvalidFields([]);
                setAddUserError(null);
                try {
                    const response = await axios.post('/dashboard/account/users', {...user});
                    if(response.status === 200){
                        getUsers();
                        setShowAddUserModal(false);
                    }
                } catch (error) {
                    setAddUserError(error.response.data.message);
                } finally {
                    setAddUserLoading(false);
                    setShowAddUserModal(false);
                }
            }
        }

    function headerButtonComponent() {
        return <Button variant="secondary" size="small" onClick={() => setShowAddUserModal(true)}>Add New User</Button>
    }

  return (
      <div className="px-4 py-6">
          <DashboardHeader headerTitle={"Account Users"} buttonComponent={headerButtonComponent}/>
        {
            loading ?
                <>
                            < div className = "w-full h-40 animate-pulse flex items-center justify-between gap-x-4 border-b rounded-xl border-gray-900/5 bg-gray-200 p-6" ></div >
                            <div className="w-full h-40 my-4 animate-pulse flex items-center justify-between gap-x-4 border-b rounded-xl border-gray-900/5 bg-gray-200 p-6"></div>
                            <div className="w-full h-40 animate-pulse flex items-center justify-between gap-x-4 border-b rounded-xl border-gray-900/5 bg-gray-200 p-6"></div>
                        </>
                        :
users && users.length > 0 ?
<>
{error && <div className="text-red-500">{error}</div>}
    <AccountUsersTable users={users} setShowRemoveUserModal={setShowRemoveUserModal} setRemoveUser={setRemoveUser}/>
                      </>
    :
    <div>No users found</div>
}
<AddUserModal
showAddUserModal={showAddUserModal}
setShowAddUserModal={setShowAddUserModal}
saveUser={saveUser}
user={user}
handleUserChange={handleUserChange}
accountType={accountType}
invalidFields={invalidFields}
addUserError={addUserError}
roleOptions={roleOptions}
addUserLoading={addUserLoading}/>

<RemoveUserModal
showRemoveUserModal={showRemoveUserModal}
setShowRemoveUserModal={setShowRemoveUserModal}
handleRemoveUser={handleRemoveUser}
removeUserLoading={removeUserLoading}
removeUser={removeUser}/>
        </div>
  )
}

export default AccountUsersPage