import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../Table/table'
import Button from "../Button/Button";


const AccountUsersTable = ({users, setShowRemoveUserModal, setRemoveUser}) => {

  return (
    <div>
        <Table bleed={true}>
            <TableHead>
            <TableRow>
                <TableHeader>Name</TableHeader>
                <TableHeader>Email</TableHeader>
                <TableHeader>Role</TableHeader>
                <TableHeader className="text-center">Actions</TableHeader>
            </TableRow>
            </TableHead>
            <TableBody>
            {users?.length > 0 ? users.map((user) => (
                <TableRow key={`user-${user.userId}-${user.role}`}  title={`User #${user.userId}-${user.role}`} className="w-full">
                <TableCell>{user.name}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.role}</TableCell>
                <TableCell className="flex justify-center"><Button variant="primary" size="sm" onClick={() => {setShowRemoveUserModal(true); setRemoveUser(user)}}>Remove</Button></TableCell>
                </TableRow>
            )):
            <TableRow className="text-center">
                <TableCell colSpan="4">No users found</TableCell>
            </TableRow>
            }
            </TableBody>
        </Table>
    </div>
  )
}

export default AccountUsersTable